import { getGPT } from './gpt';

// Store for global targeting parameters
let globalTargetingParams: Record<string, string> = {};
let isInitialized = false;

// Promise to track initialization status
let initializationPromise: Promise<void> | null = null;

/**
 * Extracts the playlist slug from a referrer string
 */
export function getPlaylistFromReferrer(referrer?: string): string | undefined {
  if (!referrer) return undefined;

  // Check for playlist referrer in format playlist-slug_name
  if (referrer.startsWith('playlist-')) {
    return referrer.substring('playlist-'.length);
  }

  return undefined;
}

/**
 * Initializes essential page-level targeting that should be present for all ads
 * Returns a promise that resolves when targeting is initialized
 */
export async function initializePageTargeting(): Promise<void> {
  // Return existing promise if already initializing
  if (initializationPromise) return initializationPromise;

  // Return immediately if already initialized
  if (isInitialized) return Promise.resolve();

  // Create new initialization promise
  initializationPromise = new Promise<void>(async resolve => {
    try {
      const googletag = await getGPT();
      if (
        !googletag ||
        !googletag.pubads ||
        typeof googletag.pubads !== 'function'
      ) {
        resolve();
        return;
      }

      const pubadsService = googletag.pubads();
      if (!pubadsService) {
        resolve();
        return;
      }

      // Initialize with empty targeting rather than setting defaults
      // adType and companionAds will be set contextually by individual components
      const essentialParams: Record<string, string> = {};

      // Apply to pubads service
      Object.entries(essentialParams).forEach(([key, value]) => {
        pubadsService.setTargeting(key, value);
      });

      // Update global store
      globalTargetingParams = {
        ...globalTargetingParams,
        ...essentialParams
      };

      isInitialized = true;
    } catch (error) {
      console.error('Error initializing page targeting:', error);
    } finally {
      // Always resolve to avoid hanging promises
      resolve();
      // Clear the promise reference after completion
      initializationPromise = null;
    }
  });

  return initializationPromise;
}

/**
 * Waits for targeting to be initialized and then returns
 * Can be awaited by components that need targeting to be ready
 */
export async function waitForTargetingReady(): Promise<void> {
  if (isInitialized) return Promise.resolve();
  return initializePageTargeting();
}

/**
 * Parses a custom parameters string in the format of "key1=value1&key2=value2"
 * and returns an object with the parsed parameters
 */
export function parseCustParams(
  custParamsString?: string
): Record<string, string> {
  if (!custParamsString) return {};

  try {
    return custParamsString.split('&').reduce(
      (acc, param) => {
        const [key, value] = param.split('=').map(decodeURIComponent);
        if (key && value) {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, string>
    );
  } catch (error) {
    console.error('Error parsing custom parameters:', error);
    return {};
  }
}

/**
 * Sets global targeting parameters that will be applied to all GPT ad requests
 * This is useful for sharing targeting between DAI video ads and companion banner ads
 */
export async function setGlobalTargeting(
  params: Record<string, string | undefined>
): Promise<void> {
  // Wait for targeting to be initialized
  await waitForTargetingReady();

  try {
    // Filter out undefined values and store them
    const filteredParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {} as Record<string, string>
    );

    if (Object.keys(filteredParams).length === 0) {
      return;
    }

    // Special handling for series and playlist targeting
    const urlParams =
      typeof window !== 'undefined'
        ? new URLSearchParams(window.location.search)
        : new URLSearchParams();

    // Extract playlist from referrer query param if present
    const referrer = urlParams.get('referrer');
    // Handle null case explicitly
    const playlistFromReferrer = referrer
      ? getPlaylistFromReferrer(referrer)
      : undefined;

    // If we have a playlist from referrer, add it to targeting
    if (playlistFromReferrer && !filteredParams.playlist) {
      filteredParams.playlist = playlistFromReferrer;
    }

    // Check URL path for series and playlist patterns
    if (typeof window !== 'undefined') {
      const path = window.location.pathname;

      // Handle series pages
      if (path.includes('/series/')) {
        const seriesMatch = /\/series\/([^/]+)/.exec(path);
        if (seriesMatch && seriesMatch[1] && !filteredParams.series) {
          filteredParams.series = seriesMatch[1];
        }
      }

      // Handle playlist pages when not already set
      if (path.includes('/playlists/') && !filteredParams.playlist) {
        const playlistMatch = /\/playlists\/\d+\/([^/]+)/.exec(path);
        if (playlistMatch && playlistMatch[1]) {
          filteredParams.playlist = playlistMatch[1];
        }
      }
    }

    // Update global store
    globalTargetingParams = {
      ...globalTargetingParams,
      ...filteredParams
    };

    // Apply to GPT
    const googletag = await getGPT();
    if (
      !googletag ||
      !googletag.pubads ||
      typeof googletag.pubads !== 'function'
    ) {
      return;
    }

    const pubadsService = googletag.pubads();
    if (!pubadsService) {
      return;
    }

    // Apply each parameter to pubads service
    Object.entries(filteredParams).forEach(([key, value]) => {
      pubadsService.setTargeting(key, value);
    });
  } catch (error) {
    console.error('Error setting global targeting:', error);
  }
}

/**
 * Gets all current global targeting parameters
 */
export function getGlobalTargeting(): Record<string, string> {
  return { ...globalTargetingParams };
}

/**
 * Applies all current global targeting parameters to a specific ad slot
 */
export async function applyGlobalTargetingToSlot(slot: any): Promise<void> {
  if (!slot || !slot.setTargeting) {
    return;
  }

  // Wait for targeting to be initialized first
  await waitForTargetingReady();

  try {
    // Apply each parameter to the slot
    Object.entries(globalTargetingParams).forEach(([key, value]) => {
      try {
        slot.setTargeting(key, String(value));
      } catch (e) {
        console.warn(`Failed to set targeting '${key}' on slot:`, e);
      }
    });
  } catch (error) {
    console.error('Error applying global targeting to slot:', error);
  }
}

/**
 * Gets the appropriate adType and companionAds targeting values based on isVideoAd flag
 */
export function getAdTypeTargeting(isVideoAd: boolean): Record<string, string> {
  if (isVideoAd) {
    return {
      adType: 'video',
      companionAds: 'true'
    };
  } else {
    return {
      adType: 'display'
    };
  }
}
